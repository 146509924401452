import React from "react";
import styles from "./closed-courses.module.scss";
import Spacing from "@components/ui/spacing";
import Title from "@components/ui/title";
import ActionLink from "@components/ui/action-link";
import CourseCard from "@components/ui/course-card";
import { navigateTo } from "gatsby";
import Swiper from "react-id-swiper";
import "swiper/swiper.scss";
import PropTypes from "prop-types";
import { useCoursesMetadata } from "@queries/use-courses-metadata";
import { isCourseClosedForSignUp } from "@utilities/course-data-helpers";

const getCoursePath = (node, pathsToCourses) => {
  const coursePage = pathsToCourses.edges.filter(
    (edge) => edge.node.context.jsonFileId === node.id
  )[0];
  return coursePage.node.path;
};

const renderCourseCards = (finishedCourses, coursePaths, limit) => {
  if (finishedCourses && finishedCourses.length === 0) {
    return null;
  }

  return finishedCourses.slice(0, limit).map((edge) => {
    const {
      general: { courseName },
      description: { scholarity, imageUrl },
    } = edge.node;
    const handleClick = () => navigateTo(getCoursePath(edge.node, coursePaths));

    return (
      <div key={edge.node.id}>
        <CourseCard
          onClick={handleClick}
          title={courseName}
          subtitle={scholarity}
          imageUrl={imageUrl}
        />
      </div>
    );
  });
};

const ClosedCourses = (props) => {
  const { allCoursesJson, pathsToCourses } = useCoursesMetadata();
  const courses = allCoursesJson.edges.filter(isCourseClosedForSignUp);

  const gridStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(255px, 1fr))",
    gridColumnGap: "30px",
    gridRowGap: "30px",
    justifyItems: "center",
  };

  const sliderParams = {
    width: 255,
    spaceBetween: 30,
    updateOnWindowResize: true,
  };

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <section>
          <Title value="Cursos que fizeram história" />
        </section>
        <section className={styles.mobileHidden}>
          <div>
            <ActionLink path="/cursos" label="ver todos" width="255px" />
          </div>
        </section>
      </div>

      <Spacing>
        <div className={styles.desktopCourseList}>
          <div style={{ ...gridStyles }}>
            {renderCourseCards(courses, pathsToCourses, props.limit)}
          </div>
        </div>
      </Spacing>

      <div className={styles.mobileCourseList}>
        <Swiper {...sliderParams}>
          {renderCourseCards(courses, pathsToCourses, props.limit)}
        </Swiper>
      </div>

      <section className={`${styles.action} ${styles.desktopHidden}`}>
        <ActionLink path="/cursos" label="ver todos" width="auto" />
      </section>
    </div>
  );
};

export default ClosedCourses;

ClosedCourses.defaultProps = {
  limit: 6,
};

ClosedCourses.propTypes = {
  limit: PropTypes.number,
};
