import React from 'react';
import PropTypes from 'prop-types';
import styles from './course-card.module.scss';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const CourseCard = (props) => {
  const breakpoints = useBreakpoint();

  const config = {
    width: breakpoints.mobile ? props.mobile.size : props.desktop.size,
    height: breakpoints.mobile ? props.mobile.size : props.desktop.size,
    margin: breakpoints.mobile ? props.mobile.margin : props.desktop.margin,
  };

  return (
    <div onClick={props.onClick} className={styles.container} style={{ width: config.width }}>
      
      <div className={styles.courseImage} style={{ width: config.width, height: config.height }}>
        {
          <img src={props.imageUrl.publicURL} alt={props.imageUrl.name} />
        }
      </div>
      
      <div className={styles.cardOverlay} style={{ left: config.margin, right: config.margin, bottom: config.margin }}>
        {
          props.title &&
          <h1 className={styles.title}>{props.title}</h1>
        }

        {
          props.subtitle &&
          <p className={styles.subtitle}>{props.subtitle}</p>
        }
      </div>

    </div>
  );
};

export default CourseCard;

CourseCard.defaultProps = {
  onClick: () => {},
  desktop: {
    size: 255,
    margin: 40,
  },
  mobile: {
    size: 255,
    margin: 40,
  },
};

CourseCard.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.shape({
    name: PropTypes.string,
    publicURL: PropTypes.string,
  }).isRequired,
  desktop: PropTypes.shape({
    size: PropTypes.number,
    margin: PropTypes.number,
  }),
  mobile: PropTypes.shape({
    size: PropTypes.number,
    margin: PropTypes.number,
  }),
};
